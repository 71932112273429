/******MIXIN******/
@mixin prefixonprop($prop, $val...) {
    -webkit-#{$prop}: $val;
    -moz-#{$prop}: $val;
    -o-#{$prop}: $val;
    -ms-#{$prop}: $val;
    #{$prop}: $val;
}

@mixin prefixonvalue($prop, $val...) {
    #{$prop}: -webkit-#{$val};
    #{$prop}: -moz-#{$val};
    #{$prop}: -o-#{$val};
    #{$prop}: -ms-#{$val};
    #{$prop}: $val;
}

.animBlock[data-position="up"] h2 span{
  opacity: 0;
  @include prefixonprop(transform, translateY(20%));
}
.animBlock[data-position="up"] .button-black{
  opacity: 0;
  @include prefixonprop(transform, translateY(20%));
  @include prefixonprop(transition, width .6s cubic-bezier(.25,.1, 0,1));
}
.animBlock[data-position="up"] p{
  opacity: 0;
  @include prefixonprop(transform, translateY(20%));
  transition-delay: 0.43s!important;
}

.animBlock[data-position="up"].viewed h2 span,
.animBlock[data-position="up"].viewed .button-black,
.animBlock[data-position="up"].viewed p{
  @include prefixonprop(transform, translateY(0));
  @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out, width 0.6s cubic-bezier(.25,.1, 0,1));
  opacity: 1;
}

.animBlock[data-position="up"].viewed .button-black{
   @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out, width 0.6s 0s cubic-bezier(.25,.1, 0,1));
}

.animBlock[data-position="up"].viewed h2 {
  @for $i from 1 through 2{ 
    .item-#{$i}{
        transition-delay: 0.1s + ($i / 7);
    }
  }
}

.animated{
  .bg{
    @include prefixonprop(transform, scaleY(1));
    @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
    transform-origin: top;
    transition-delay: 1s;
  }  
  .hero {
    display: block;
    .container {
      .col-12{
        .button-black{
          opacity: 1;
          @include prefixonprop(transform, translateY(0));
          @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
          transition-delay: 0.4s;
        }
      }
      .col-img {
        .full-img-product{
        	div{
        		@include prefixonprop(transform, scale(1,1));
          	&.next{
	          	img{
	          		transform: scale(1,1);
	          	}
	          }
        		&:after{
	        		content: "";
	        		width: 100%;
	        		height: 100%;
	        		position: absolute;
	        		left: 0;
	        		top: 0;
	        		background-color: #ffffff;
	        		z-index: 99;
	        		@include prefixonprop(transform, scaleY(0));
	        		@include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
	        		transform-origin: bottom;
	        		transition-delay: 1s;
	        	}
	        	img{
	        		transform: scale(1,1);
	        	}
        	}
        }
        .card-container{
          h6{
            transition-delay: 0s;
            opacity: 0;
            transform: translateY(10%); 
            &.visible{
              transform: translateY(0);
              opacity: 1;
            }
          }
          .card-title{
            h6{
              &.visible{
                transform: translateY(0);
                opacity: 1;
              }
            }
          }
          .footer-card{
            h6{
             	&.visible{
                transform: translateY(0);
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.preload{
  .bg{
    @include prefixonprop(transform, scaleY(0));
  }
  #wrapper_header {
	  #header{
	    .container{
	      .logo{
	      	opacity: 0;
          @include prefixonprop(transform, translateY(20%));
	      }
	      .link-right{
	      	opacity: 0;
          @include prefixonprop(transform, translateY(20%));
	      }
	    }
		}
	}
  .hero {
    display: block;
    .container {
      .col-img {
        .card-container{
         	opacity: 0;
          @include prefixonprop(transform, translateY(-10px));
          .img-container{         
            >div{
         			opacity: 0;
          		@include prefixonprop(transform, translateY(-10px));
              span{
           	
              }
            }
          }
        }
        .full-img-product{
        	div{
        		&:after{
	        		content: "";
	        		width: 100%;
	        		height: 100%;
	        		position: absolute;
	        		left: 0;
	        		top: 0;
	        		background-color: #ffffff;
	        		z-index: 99;
	        		@include prefixonprop(transform, scaleY(1));
	        		transform-origin: top;
	        	}
        	}
        	>div{
        		@include prefixonprop(transform, scale(1.3,1.3));
        		transform-origin: center;
        	}
        }
      }
      .content-left {
        h2{
          span{
            opacity: 0;
            @include prefixonprop(transform, translateY(20%));
          }
        }
        p{
          opacity: 0;
          @include prefixonprop(transform, translateY(20%));
        }
        .button-black{
          opacity: 0;
          @include prefixonprop(transform, translateY(30%));
        }
      }
    }
  }
}

#customized-homepage{
  .row{ 
    .animBlock{
      .container-animated{
        img{
          opacity: 0;
          @include prefixonprop(transform, translateY(20%));
        }
      }
    }
    .viewed{
      .container-animated{
        img{
          opacity: 1;
          @include prefixonprop(transform, translateY(0));
          @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
        }
        @for $i from 1 through 2{ 
          .img-#{$i}{
              transition-delay: 0.1s + ($i / 7);
          }
        }
      }
    }   
  }
}

#customer-insights{
  .container{
    .row{
      .col-md-6{
        &.animBlock{
          .container-animated{
            img{
              opacity: 0;
              @include prefixonprop(transform, translateY(20%));
            }
          }
        }
        &.viewed{
          .container-animated{
            img{
              opacity: 1;
              @include prefixonprop(transform, translateY(0));
              @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
            }
            @for $i from 1 through 4{ 
              .img-#{$i}{
                transition-delay: 1s + ($i / 8) !important;
              }
            }
          }
        }   
      }
    }
  }
}

#contact{
  .container{
    .bg-black.animBlock[data-position="left"] {
      @include prefixonprop(transform, scaleX(0));
      &.animBlock[data-position="left"].viewed{
        @include prefixonprop(transform, scaleX(1));
        transform-origin: right;
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
      }
    }
    .col-12{
      &.animBlock{
        h2{
          @for $i from 1 through 2{ 
            .item-#{$i}{
                transition-delay: 1s + ($i / 12);
            }
          }
        }
        p{
          transition-delay: 1.1s!important;
        }
        form{
          .field{
            opacity: 0;
            @include prefixonprop(transform, translateY(20%));
          }
        }
      }
      form{
        @for $i from 1 through 4{ 
          .item-#{$i}{
            transition-delay: 1s + ($i / 8) !important;
          }
        }
      }
      &.viewed{
        form{
          .field{
            opacity: 1;
            @include prefixonprop(transform, translateY(0));
            @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
          }
        }
      }
    }
  }
}

#brands-container{
  .container.animBlock{
    h5{
      opacity: 0;
      @include prefixonprop(transition, opacity 0.4s ease-out);
    }
    .container-logos{
      img{
        opacity: 0;
        @include prefixonprop(transform, translateY(20%));
      }
    }
  }
  .container.viewed{
    h5{
      opacity: 1;
      @include prefixonprop(transform, translateY(0));
      @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
    }
    .container-logos{
      @for $i from 1 through 6{ 
        .logo-#{$i}{
          transition-delay: 0.5s + ($i / 8) !important;
        }
      }
      img{
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
      }
    }
  }
}

#butler-section{
  .container{
    .animBlock{
      img{
        opacity: 0;
        @include prefixonprop(transform, translateY(20%));
      }
    }
    .viewed{
      img{
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
      }
    }  
  }
}